.player-wrapper {
  position: relative;
  /* Player ratio: 100 / (1280 / 720) */
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}