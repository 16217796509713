body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  text-align: -webkit-center;
}

@font-face {
  font-family: "BabyEliot";
  src: local("BabyEliot"),
   url("./Styles/Fonts/Baby\ Eliot.ttf") format("truetype");
  font-weight: normal;
  }